.header {
  position: sticky;
  top: 0;
  z-index: 1;

  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* make elements on same level */
  background-color:#EC2C93;
  color: black;

  border-bottom: 2px solid #EC2C93;
  width: 100%
}

.header_right {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px auto;
  color: white;

  padding-right: 5px;

 
}

.header_info {
  display: flex;
  align-items: center;
  /* font-size: 22px; */
}




#title {
  color: white;
 font-size: 3vh;
  font-family:sans-serif;
  font-weight: 800;
}


.MuiTypography{
  font-size: 92px;
}